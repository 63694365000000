import { gsap } from 'gsap';
import random from 'lodash.random';
import shuffle from 'lodash.shuffle';
import throttle from 'lodash.throttle';

import { MorphSVGPlugin } from '../../assets/js/MorphSVGPlugin3.min';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const spots = () => {
  gsap.registerPlugin(MorphSVGPlugin);

  const spotMophings = {
    spot1: [
      'M253 202.215C253 292.084 203.634 338.37 129.791 361.616C55.9492 384.862 30.1724 312.218 9.90147 225.046C-10.3695 137.873 0.718445 129.571 41.0148 77.2675C81.3111 24.964 106.975 -22.7734 170.861 11.6805C234.747 46.1345 253 112.346 253 202.215Z',
      'M227.754 236.158C199.511 285.158 165.254 324.13 139.754 332.158C114.254 340.185 63.9521 304.818 28.452 219.818C-7.04812 134.818 -11.8442 93.6214 28.4521 41.3179C68.7484 -10.9857 175.566 -10.136 239.452 24.3179C303.338 58.7719 255.997 187.158 227.754 236.158Z',
      'M264.5 236.5C236.257 285.5 224.788 358.154 173.788 365.182C122.788 372.21 53.9999 317 18.4997 232C-17.0004 147 1.73962 69.5 70.4998 27.4999C139.26 -14.5001 234.26 -7.99965 275 48.5003C315.74 105 292.743 187.5 264.5 236.5Z',
      'M263.5 224C257 258.5 243.5 314 144.5 322C45.4997 330 -4.00023 268.5 0.499849 178C4.99993 87.5003 77 0.5 118 0.5C159 0.5 198.76 7.00002 239.5 63.5C280.24 120 270 189.5 263.5 224Z',
      'M257.428 249.669C239.428 281.169 151.427 335.169 82.9275 321.669C14.4277 308.169 -3.10282 233.168 0.42766 162.168C3.95814 91.1685 19.4277 14.6682 94.4277 1.66813C169.428 -11.332 206.688 54.1685 247.428 110.669C288.167 167.169 275.428 218.169 257.428 249.669Z',
    ],
    spot2: [
      'M187.502 60.1586C230.706 96.4382 263.354 164.532 219.094 198.024C174.833 231.517 81.4468 210.019 47.9539 165.758C-12.087 86.4148 -13.2643 45.9223 30.9961 12.4294C75.2566 -21.0635 144.299 23.879 187.502 60.1586Z',
      'M219.502 108.159C262.706 144.438 295.354 212.532 251.094 246.025C206.833 279.517 113.447 258.019 79.9539 213.758C19.913 134.415 18.7357 93.9224 62.9961 60.4295C107.257 26.9366 176.299 71.8791 219.502 108.159Z',
      'M243.564 106.5C280.465 141.658 349.402 215.401 300.5 266.5C251.598 317.599 123.465 323.158 85.5637 238C45.1054 147.097 41.3033 98.4929 85.5638 65C158.465 27.6588 206.662 71.3411 243.564 106.5Z',
      'M245.099 80.0001C288.302 116.28 295.76 208.507 251.5 242C207.24 275.493 120.493 299.76 87.0003 255.5C26.9594 176.157 40.5988 139.342 62.5001 99.5004C84.4014 59.6589 201.895 43.7205 245.099 80.0001Z',
      'M193.509 61.9997C273.107 77.9996 307.51 129 295.51 193C283.51 257 181 321 142.51 311.999C103.053 302.773 61.5097 237.5 61.5098 159C61.51 80.4993 113.911 45.9999 193.509 61.9997Z',
      'M168.5 80.9997C248.098 96.9996 285.99 104.001 293 164C300.01 223.999 216.491 275 146.501 268C106.181 263.967 43.5004 237.5 43.5005 159C43.5006 80.4996 88.9025 64.9999 168.5 80.9997Z',
    ],
    spot3: [
      'M147.227 214.687C-98.2 200.039 24.5439 43.3709 83.0877 24.4963C201.237 -13.5953 478.486 -30.8333 472.365 162.626C470.5 221.577 435.785 294.468 317.687 327.541C208.969 357.988 241.74 220.328 147.227 214.687Z',
      'M176.227 246.688C-69.2002 232.039 53.5436 75.371 112.087 56.4964C230.237 18.4048 507.486 1.1668 501.365 194.626C499.5 253.577 464.784 326.468 346.686 359.542C237.969 389.988 271 252.344 176.227 246.688Z',
      'M105 324C44 232.5 82.5002 113.524 157.5 66.5001C232.5 19.4765 365.895 -83.7703 464.5 146C499.635 227.874 469.098 334.927 350.999 368C242.282 398.446 148.395 389.092 105 324Z',
      'M149 293.5C64.0442 223.673 41.4999 121.999 101.5 49.4998C161.5 -22.9998 315.5 -95.0003 426.5 110C468.922 188.347 468.611 354.238 406 392.5C352.001 425.5 222 353.5 149 293.5Z',
      'M122.669 329.547C75.6691 290.917 55.1691 170.548 141.669 55.5478C228.17 -59.4522 325.669 -38.9522 414.669 110.548C460.244 187.103 440.669 274.853 351.169 329.548C297.169 362.547 184.169 380.095 122.669 329.547Z',
      'M189.401 271.196C79.401 289.196 -34.5991 163.196 98.4011 66.6955C231.401 -29.8045 452.901 2.6953 491.902 85.1955C529.979 165.743 491.019 308.069 390.401 337.696C300.401 364.196 321.433 249.59 189.401 271.196Z',
    ],
  };

  const spotIndexes = {
    spot1: 1,
    spot2: 1,
    spot3: 1,
  };

  const spotTargets = {
    spot1: 'spots__item_first',
    spot2: 'spots__item_second',
    spot3: 'spots__item_third',
  };

  const spotAnimations = {};

  const animation = clipId => {
    if (spotAnimations[clipId] === 'progress') {
      return;
    }

    const spotClassName = spotTargets[clipId];
    const pathElement = document.querySelector(`#${clipId} > path`);
    const spotElement = document.querySelector(`.${spotClassName}`);

    const spotStyles = window.getComputedStyle(spotElement);
    const newTop = parseFloat(spotStyles.top) + random(-50, 50);
    const newLeft = parseFloat(spotStyles.left) + random(-50, 50);

    gsap.to(spotElement, { duration: 1, top: newTop, left: newLeft });

    gsap.to(pathElement, {
      duration: 1,
      morphSVG: spotMophings[clipId][spotIndexes[clipId]],
      onStart() {
        spotAnimations[clipId] = 'progress';
      },
      onUpdate() {
        // TODO: do it only in safary
        const el = document.querySelector(`.${spotClassName}`);

        el.style.display = 'none';
        el.offsetWidth;
        el.style.display = 'block';
      },
      onComplete() {
        spotAnimations[clipId] = 'final';
      },
    });

    spotIndexes[clipId] += 1;

    if (spotIndexes[clipId] >= spotMophings[clipId].length) {
      spotIndexes[clipId] = 0;
    }
  };

  const init = () => {
    const listener = throttle(async () => {
      for (const clipId of shuffle(Object.keys(spotMophings))) {
        animation(clipId);
        // eslint-disable-next-line no-await-in-loop
        await sleep(random(100, 700));
      }
    }, 300);

    document.addEventListener('mousemove', listener);
    document.addEventListener('scroll', listener);
  };

  init();
};
