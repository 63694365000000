import { projects as desktopProjects } from '../components/projects/projects.desktop';
import { projects as mobileProjects } from '../components/projects/projects.mobile';
import { about } from '../components/about/about';
import { spots } from '../components/spots/spots';

about();
spots();

if (window.innerWidth >= 600) {
  desktopProjects();
} else {
  mobileProjects();
}
