export const getCustomPropery = (element, propertyKey, mapper) => {
  const style = window.getComputedStyle(element);
  const value = style.getPropertyValue(`--${propertyKey}`);

  if (mapper) {
    return mapper(value);
  }

  return value;
};
