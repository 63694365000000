import { gsap } from 'gsap';

export const contacts = () => {
  const open = document.getElementById('contacts_button');
  const details = document.getElementById('contacts_details');

  let animation;

  const duration = 0.2;

  document.addEventListener('click', ({ target }) => {
    if (target.id === 'contacts_button') {
      animation = gsap
        .timeline()
        .to(open, {
          color: 'black',
          duration,
        })
        .to(details, {
          translateY: 0,
          duration,
        });
    } else {
      if (animation) {
        animation.reverse();
      }
    }
  });
};
