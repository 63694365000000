/* eslint-disable no-use-before-define */

import { getAllElements } from './helpers/getAllElements';
import { makeAnimation } from './helpers/animation/makeAnimation';

const createListenerOnClick = element => {
  const animation = makeAnimation(
    element,
    {
      onStart: () => {
        element.removeEventListener('click', onClick);
      },
      onReverseComplete: () => {
        element.addEventListener('click', onClick);
        document.removeEventListener('click', onOutsideClick);
      },
    },
    2,
  );

  const onOutsideClick = () => animation.reverse();

  const onClick = () =>
    setTimeout(() => {
      document.addEventListener('click', onOutsideClick);
      animation.restart();
    });

  element.addEventListener('click', onClick);
};

export const projects = () => {
  for (const element of getAllElements()) {
    createListenerOnClick(element);
  }
};
