import { getRowLength } from './getRowLength';
import { getAllElements } from '../getAllElements';

const getRowNumber = (rowLength, index) =>
  Math.ceil((index + 1) / rowLength) - 1;
const getCellNumber = (rowLength, index) => index % rowLength;

export const getPositionInMatrix = element => {
  const allElements = getAllElements(element);
  const rowLength = getRowLength(element);

  const index = allElements.indexOf(element);

  return {
    row: getRowNumber(rowLength, index),
    cell: getCellNumber(rowLength, index),
  };
};
