import range from 'lodash.range';

import { getPositionStatus } from './getPositionStatus';
import { getPositionInMatrix } from './getPositionInMatrix';
import { getElementsInMatrix } from './getElementsInMatrix';

const getForLast = (cell, size) => {
  const first = cell;

  return range(first, first - size);
};

const getForFirst = (cell, size) => {
  const first = cell;

  return range(first, first + size);
};

const getForMiddle = (cell, size) => {
  const first = cell - 1;

  return range(first, first + size);
};

export const getOverlappedElements = (element, size) => {
  const { isFirst, isLast } = getPositionStatus(element);
  const matrix = getElementsInMatrix(element);
  const position = getPositionInMatrix(element);

  const rowNumbers = range(0, size)
    .map(rowNumber => position.row - rowNumber)
    .filter(rowNumber => rowNumber >= 0);
  const cellNumbers = isLast
    ? getForLast(position.cell, size)
    : isFirst
    ? getForFirst(position.cell, size)
    : getForMiddle(position.cell, size);

  const c = [];
  rowNumbers.forEach(rowNumber => {
    const row = matrix[rowNumber];

    cellNumbers.forEach(cellNumber => {
      const cell = row[cellNumber];

      if (cellNumber === position.cell && rowNumber === position.row) {
        return;
      }

      c.push(cell);
    });
  });

  return c;
};
