import chunk from 'lodash.chunk';

import { getRowLength } from './getRowLength';
import { getAllElements } from '../getAllElements';

export const getElementsInMatrix = element => {
  const allElements = getAllElements();

  return chunk(allElements, getRowLength(element));
};
