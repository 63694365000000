import { getCustomPropery } from './getCustomPropery';
import { getPositionStatus } from './getPositionStatus';

const GAP = 12;

const defineOrigin = ({ isFirst, isLast }, size) => {
  if (isFirst) {
    return 'left bottom';
  }

  if (isLast) {
    return 'right bottom';
  }

  if (size % 2 === 0) {
    return 'right bottom';
  }

  return 'center bottom';
};

export const getAnimationParameters = (element, size) => {
  const { width } = element.getBoundingClientRect();

  // TODO: GET GAP FROM custom properties
  const newWidth = width * size + (size - 1) * GAP;

  const transformOrigin = defineOrigin(getPositionStatus(element), size);
  const duration = getCustomPropery(element, 'animation-duration', parseFloat);

  return {
    transformOrigin,
    ratio: newWidth / width,
    duration,
  };
};
