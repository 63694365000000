import { gsap } from 'gsap';
import uniq from 'lodash.uniq';
import createStore from 'storeon';

import { getAnimationParameters } from './getAnimationParameters';
import { getOverlappedElements } from './getOverlappedElements';

const siblingModule = module => {
  module.on('@init', () => ({
    forward: [],
    backward: [],
    duration: 0,
  }));

  module.on('setDuration', (_, newDuration) => ({ duration: newDuration }));

  module.on('add', ({ backward, forward }, { toBackward, toForward }) => ({
    backward: uniq([...backward, ...(toBackward || [])]),
    forward: uniq([...forward, ...(toForward || [])]),
  }));

  module.on('remove', ({ backward, forward }, { toBackward, toForward }) => ({
    backward: backward.filter(i => (toBackward || []).includes(i)),
    forward: forward.filter(i => (toForward || []).includes(i)),
  }));
};

const store = createStore([siblingModule]);

store.on('forward', ({ duration }, toForward) => {
  gsap.to(toForward, {
    duration,
    opacity: 0,
    onComplete: () => {
      store.dispatch('remove', { toForward });
    },
  });

  store.dispatch('add', { toForward });
});

store.on('backward', ({ duration }, toBackward) => {
  gsap.to(toBackward, {
    duration,
    opacity: 1,
    onComplete: () => {
      store.dispatch('remove', { toBackward });
    },
  });

  store.dispatch('add', { toBackward });
});

export const makeAnimation = (element, externalCallbacks, size = 3) => {
  const siblings = getOverlappedElements(element, size);

  const { transformOrigin, duration, ratio } = getAnimationParameters(
    element,
    size,
  );

  store.dispatch('setDuration', duration);

  const customCallbacks = {
    onStart: () => {
      store.dispatch('forward', siblings);
      if (externalCallbacks.onStart) {
        externalCallbacks.onStart();
      }
    },
    onReverseStart: () => {
      store.dispatch('backward', siblings);
      if (externalCallbacks.onReverseStart) {
        externalCallbacks.onReverseStart();
      }
    },
  };

  const callbacks = {
    onComplete: () => {
      if (externalCallbacks.onComplete) {
        externalCallbacks.onComplete();
      }
    },
    onReverseComplete: () => {
      if (externalCallbacks.onReverseComplete) {
        externalCallbacks.onReverseComplete();
      }
    },
  };

  const animation = gsap
    .timeline({
      defaults: {
        duration: duration / 2,
        transformOrigin,
      },
      paused: true,
      ...callbacks,
    })
    .to(element, {
      scaleY: ratio,
      zIndex: 2,
    })
    .to(element, {
      scaleX: ratio,
    });

  return {
    restart: () => {
      customCallbacks.onStart();
      animation.restart();
    },
    reverse: () => {
      customCallbacks.onReverseStart();
      animation.reverse();
    },
  };
};
