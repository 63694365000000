const CLEARANCE = 50;

export const getPositionStatus = element => {
  const { x, width } = element.getBoundingClientRect();

  const isFirst = x - CLEARANCE <= 0;
  const isLast = x + width + CLEARANCE >= window.innerWidth;

  const isMiddle = !isFirst && !isLast;

  return { isFirst, isLast, isMiddle };
};
