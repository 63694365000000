/* eslint-disable no-use-before-define */

import { getAllElements } from './helpers/getAllElements';
import { makeAnimation } from './helpers/animation/makeAnimation';

const createListenerOnMouseOver = element => {
  const animation = makeAnimation(element, {
    onReverseComplete: () => {
      element.addEventListener('mouseover', mouseoverHandler);
      element.removeEventListener('mouseleave', mouseleaveHandler);
    },
  });

  const mouseoverHandler = () => {
    element.removeEventListener('mouseover', mouseoverHandler);
    element.addEventListener('mouseleave', mouseleaveHandler);

    animation.restart();
  };

  const mouseleaveHandler = () => animation.reverse();

  return mouseoverHandler;
};

export const projects = () => {
  for (const element of getAllElements()) {
    element.addEventListener('mouseover', createListenerOnMouseOver(element));
  }
};
